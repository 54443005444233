import React from 'react'

import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ReduxAppState } from '../../utils/types'
import { withRouter } from '../../hoc/withRouter'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { generateRandomId } from '../../utils'
import { Spinner, Table } from 'react-bootstrap'
import { getPortfolio } from '../../reducers/User/getPortfolio'
import { Items } from '../../reducers/Items'
import { ArtTooltip } from '../../ReusableComponent'

export interface PortfolioPageProps {
    router: any
}

export interface StateProps {
    portfolios?: any,
    isError: boolean
}

export interface DispatchProps {
    getPortfolio: (id: string) => any
}

export interface PortfolioPageState {
    selectedTooltip: string
}

export type PortfolioProps = PortfolioPageProps & StateProps & DispatchProps

class PortfolioPage extends React.Component<PortfolioProps, PortfolioPageState> {
    constructor(props: PortfolioProps) {
        super(props)
        this.state = {
            selectedTooltip: ''
        }
    }

    componentDidMount(): void {
        const userId = this.props.router?.params?.id
        this.props.getPortfolio(userId)
    }

    setSelectedTooltip = (tooltip: any) => this.setState({ selectedTooltip: tooltip })

    render() {
        const _portfolios = this.props.portfolios?.response ?? []
        return (
            <React.Fragment>
                <div className="d-flex flex-row mx-5 portfolio-table-container ">
                    {this.props.isError && <p><FormattedMessage id='Portfolio.msg.somethingWentWrong' /></p>}
                    <Table className="amtable">
                        <thead>
                            <tr>
                                <th className="column"><FormattedMessage id='Portfolio.label.artworkName' /></th>
                                <th className="column"><FormattedMessage id='Portfolio.label.shareOwned' /></th>
                                <th className="column"><FormattedMessage id='Portfolio.label.totalAmount' /></th>
                                <th className='column' />
                            </tr>
                        </thead>
                        <tbody style={{ position: 'relative', height: this.props.portfolios?.loading && '5rem' }}>
                            {this.props.portfolios?.loading ? (
                                <Spinner animation='border' size='sm' className='am-spinner' />
                            ) : _portfolios && _portfolios.length > 0 ? _portfolios.map((item: Items) => (
                                <tr >
                                    <td>{item?.title}</td>
                                    <td>{item?.portfolio?.numberOfShare}</td>
                                    <td><FormattedNumber format={'USD'} value={Number(item?.portfolio?.totalValue ?? 0)} /></td>
                                    <td className='three-dot'>
                                        <ArtTooltip trigger='click' renderTooltip={() => (
                                            <button className="am-tooltip d-flex justify-content-evenly"><img loading='lazy' src='/Icons/edit-icon.png' width={15} height={15} className="delete-icon mt-1" /> <span className='ml-3'><FormattedMessage id='Portfolio.label.modify' /></span></button>
                                        )}><span className='three-dot-text' onClick={() => this.setSelectedTooltip(this.state.selectedTooltip === item.id ? '' : item.id)}>...</span></ArtTooltip>
                                        {/* <span onClick={() => this.onSelectToolTip(this.state.selectedTooltip === item?.id ? '' : item?.id)}>...</span> */}
                                        {/* <div className="tooltip"> */}
                                        {/* {this.state.selectedTooltip === item?.id && <span className="am-tooltip d-flex justify-content-evenly"><img loading='lazy' src='/Icons/edit-icon.png' width={15} height={15} className="delete-icon mt-1" /> <span>Modify</span></span>} */}
                                        {/* </div> */}
                                    </td>
                                </tr>
                            )) : <div><FormattedMessage id='Portfolio.label.noDataFound' /></div>}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _getPortfolio = state.api?.user?.getPortfolio
    return {
        portfolios: state.api?.user?.getPortfolio,
        isError: _getPortfolio?.statusText === 'error',
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPortfolio: ((id: string) => getPortfolio(dispatch, id))
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioPage as any))
