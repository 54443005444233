import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { logOutUser } from '../reducers/User/user'
import { ReduxAppState } from '../utils/types'
import { Routes, SubRoutes } from '../utils/routes'
import { updateMenu } from '../reducers/Menu/changeMenu'
import classNames from 'classnames'

const SidebarHOC = (WrappedComponent: any) => {
    const Sidebar = (props: any) => {
        const selectedLink = props.menu?.activeLink
        const selectedSublink = props.menu?.activeSubLink
        const [height, setHeight] = useState('100%')

        const navigateSubLink = (route: string) => {
            let link = Routes.DIRECTORY
            let sublink = route
            switch (route) {
                case Routes.DIRECTORY:
                case SubRoutes.ART_LISTING:
                    sublink = SubRoutes.ART_LISTING
                    props.router?.navigate('/listing')
                    break;
                case SubRoutes.ACCOUNT_LISTING:
                    sublink = SubRoutes.ACCOUNT_LISTING
                    props.router?.navigate('/users')
                    break;
                case SubRoutes.ADD_ART_LISTING:
                    sublink = SubRoutes.ADD_ART_LISTING
                    props.router?.navigate('/listing/add')
                    break;
                case Routes.BUSINESS_OWNER:
                    link = Routes.BUSINESS_OWNER
                    break;
                case Routes.EMPLOYEE:
                    link = Routes.EMPLOYEE
                    break;
                case Routes.SYSTEM_PROPERTY:
                    link = Routes.SYSTEM_PROPERTY
                    props.router?.navigate('/system')
                    break;
                default:
                    break;
            }
            props.updateMenu(link, sublink)
        }

        // const changes = (value) => {
        //     console.log('e', value[0].target.scrollHeight)
        //     if (value?.length > 0) {
        //         setHeight(String(value[0].target.scrollHeight))
        //     }
        // }

        // new ResizeObserver(changes).observe(document.documentElement)

        return (
            <div id="Sidebar" className="d-flex">
                <div className="sidebar" style={{ position: 'fixed', maxWidth: 181 }}>
                    <div>
                        {/* <img loading='lazy' src='/am-logo.png' /> */}
                        <div className='default-profile-container'>
                            <div className='default-profile' />
                        </div>
                        <h2 className='text-center mt-3 account-name'>{props.user?.response?.name}</h2>
                        <ul className='sidebar-list'>
                            <li onClick={() => navigateSubLink(Routes.DIRECTORY)} className={classNames({ ['active']: selectedLink === Routes.DIRECTORY, ['mb-3']: selectedLink !== Routes.DIRECTORY })}><img loading='lazy' src='/Icons/dictionary-icon.png' />Directory</li>
                            {selectedLink === Routes.DIRECTORY && <li className="expand mb-3">
                                <li onClick={() => navigateSubLink(SubRoutes.ART_LISTING)} className={classNames({ ['active']: selectedSublink === SubRoutes.ART_LISTING })}><span className='text-center'>Art Listing</span></li>
                                <li onClick={() => navigateSubLink(SubRoutes.ACCOUNT_LISTING)} className={classNames({ ['active']: selectedSublink === SubRoutes.ACCOUNT_LISTING })}><span>Account Listing</span></li>
                            </li>}
                            {/* <li onClick={() => navigateSubLink(Routes.BUSINESS_OWNER)} className={classNames({['active']: selectedLink === Routes.BUSINESS_OWNER}, 'mb-3')}><img loading='lazy' src='/Icons/business-owner.png' />Business Owner</li>
                            <li onClick={() => navigateSubLink(Routes.EMPLOYEE)} className={classNames({['active']: selectedLink === Routes.EMPLOYEE}, 'mb-3')}><img loading='lazy' src='/Icons/employee-icon.png' width={24.5} height={21} />Employee</li> */}
                            <li onClick={() => navigateSubLink(Routes.SYSTEM_PROPERTY)} className={classNames({ ['active']: selectedLink === Routes.SYSTEM_PROPERTY }, 'mb-3')}><img loading='lazy' src='/Icons/system-icon.png' width={23} height={19} />System Property</li>
                            <li onClick={() => props.logoutUser()} className='logout mb-3'><img loading='lazy' src='/Icons/logout-icon.png' height={30} />Logout</li>
                        </ul>
                    </div>
                </div>
                <div className="sidebar" style={{ opacity: 0, minWidth: 181, zIndex: -10 }} />
                <div className="content">
                    <WrappedComponent
                        {...props}
                    />
                </div>
            </div>
        )
    }

    const mapStateToProps = (state: ReduxAppState) => {
        return {
            user: state.api?.user?.authUser,
            menu: state.api?.menu?.activeMenuItem
        }
    }

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateMenu: (link: string, sublink: string) => dispatch(updateMenu(link, sublink)),
        logoutUser: () => logOutUser(dispatch)
    })

    return connect(mapStateToProps, mapDispatchToProps)(Sidebar)
}

export default SidebarHOC

