import { combineReducers } from 'redux'
import getAnalyticsReducer, { getAnalyticsInitialState, GetAnalyitcsState } from './getAnalytics'
import getAnalyticsByIdReducer, { getAnalyticsByIdInitialState, GetAnalyitcsByIdState } from './getAnalyticsDetailById'


export interface Analytics {
    id: string
    itemId: string
    noOfSoldItems: number
    createdAt: Date | string
    status: number
    [p: string]: any
}

export const analyticsInitialState: AnalyticsInitialState = {
    getAnalytics: getAnalyticsInitialState,
    getAnalyticsById: getAnalyticsByIdInitialState
}

export interface AnalyticsInitialState {
    getAnalyticsById: GetAnalyitcsByIdState
    getAnalytics: GetAnalyitcsState
}

const analyticsReducer = combineReducers({
    getAnalytics: getAnalyticsReducer,
    getAnalyticsById: getAnalyticsByIdReducer
})

export default analyticsReducer