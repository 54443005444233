import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router'
import { Route, Routes } from 'react-router-dom'
import { compose } from 'redux'
import Analytics from '../components/Analytics/Analytics'
import EditListing from '../components/EditListing/EditListing'
import AddArtListing from '../components/Home/AddArtListing'
import Home from '../components/Home/Home'
import PreviewPage from '../components/Home/PreviewPage'
import Login from '../components/Login/Login'
import SystemProperty from '../components/SystemProperty/SystemProperty'
import UserDetails from '../components/UserDetails/UserDetails'
import Users from '../components/Users/Users'
import { ReduxAppState } from '../utils/types'
import Socket from '../websocket'
import ValidateRoutes from './ValidateRoutes'
import { User } from '../reducers/User'

export interface RoutesProps {
}

export interface StateProps {
    isSignedIn: boolean
    user: User
}

export interface AppRoutesState {
}

export type AppRoutesProps = RoutesProps & StateProps

class AppRoutes extends React.Component<AppRoutesProps, AppRoutesState> {
    constructor(props: AppRoutesProps) {
        super(props)
    }

    componentDidMount(): void {
        if (this.props.isSignedIn) {
            Socket.init(this.props.user?.sub)
        }
    }

    componentDidUpdate(prevProps: Readonly<AppRoutesProps>, prevState: Readonly<AppRoutesState>, snapshot?: any): void {
        if (this.props.isSignedIn !== prevProps.isSignedIn && this.props.isSignedIn) {
            Socket.init(this.props.user?.sub)
        }
    }

    render() {
        const { isSignedIn } = this.props
        return (
            <Routes>
                <Route element={<ValidateRoutes isPublic={true} isAuth={isSignedIn} />}>
                    <Route path='/' element={<Login />} />
                </Route>
                <Route element={<ValidateRoutes isPublic={false} isAuth={isSignedIn} />}>
                    <Route path='/listing' element={<Home />} />
                    <Route path='/listing/:itemId/edit' element={<EditListing />} />
                    <Route path='/listing/add' element={<AddArtListing />} />
                    <Route path='/listing/preview' element={<PreviewPage />} />
                    <Route path='/analytics' element={<Analytics />} />
                    <Route path='/analytics/:itemId' element={<Analytics />} />
                    <Route path='/users' element={<Users />} />
                    <Route path='/user/:id' element={<UserDetails />} />
                    <Route path='/system' element={<SystemProperty />} />
                </Route>
                <Route path='*' element={!isSignedIn ? <Navigate to="/" replace /> : <Navigate to={"/listing"} replace />} />
            </Routes>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1',
        isSignedIn: state.api?.user?.authUser?.isSignedIn,
        user: state.api?.user?.authUser.response
    }
}

export default connect(mapStateToProps, null)(compose(AppRoutes as any))