export default {
    apiGateway: {
        REGION: 'ap-southeast-1',
        URL: 'https://djrhvarq1i.execute-api.ap-southeast-1.amazonaws.com/dev'
    },
    cognito: {
        REGION: 'ap-southeast-1',
        USER_POOL_ID: 'ap-southeast-1_17xrE3mv1',
        APP_CLIENT_ID: '5i4gkl7piejqk9l419q60s592n',
        IDENTITY_POOL_ID: 'ap-southeast-1:62d5fbcf-a9ab-4c6a-a38d-28b1a359b54c'
    },
    oauth: {
        DOMAIN: 'artmutualdev.auth.ap-southeast-1.amazoncognito.com',
        REDIRECT_SIGNIN: 'https://cms.app.artmutual.io/home',
        REDIRECT_SIGNOUT: 'https://cms.app.artmutual.io',
        RESPONSE_TYPE: 'code',
        SCOPE: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"]
    }
};