import React, { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import classNames from 'classnames'
import { uniqueId } from 'lodash'

export interface DropdownValues {
    label: string
    value: any
    className?: string
}

export interface DropdownProps {
    items: DropdownValues[]
    onClick: (value: any) => any
    className?: string
    itemClassName?: string
    title?: string
    value: any
}


const DropdownComponent = (props: DropdownProps) => {
    // const [title, setTitle] = useState(props.title)
    const [value, setValue] = useState(props.value)
    const _onClick = (selectedItem: DropdownValues) => {
        // setTitle(selectedItem.label)
        setValue(selectedItem.value)
        props.onClick(selectedItem.value)
    }
    return (
        <DropdownButton flip id="dropdown-basic-button" title={value === undefined && props.title ? props.title : props.items.find(_item => _item.value === value)?.label ?? 'Select'} className={classNames('artDropdown', props.className)}>
            {props.items?.length > 0 && props.items?.map((item: DropdownValues) => <Dropdown.Item key={uniqueId()} onClick={() => _onClick(item)} className={classNames('artDropdownItem', props?.itemClassName, item?.className)}>{item.label}</Dropdown.Item>)}
        </DropdownButton>
    )
}


export default DropdownComponent