import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import SidebarHOC from '../../hoc/sidebar'
import { withRouter } from '../../hoc/withRouter'
import { getAllSystemParams } from '../../reducers/SystemProperty/getAllSysParam'
import { updateSystemParam } from '../../reducers/SystemProperty/updateSystemParam'
import { ArtDropdown, ArtHeader, ArtInput } from '../../ReusableComponent'
import { ReduxAppState } from '../../utils/types'
import classNames from 'classnames'
import { SystemProperties } from '../../reducers/SystemProperty'
import { uniqueId } from 'lodash'
import Socket from '../../websocket'
import { User } from '../../reducers/User'
import { updateUserStatus } from '../../reducers/User/updateUserStatus'
import Select from 'react-select'

export interface OwnProps { }
export interface StateProps {
    systemFields: any
    systemParams: SystemProperties[]
    updateApiStatus: any
    user: User
}
export interface DispatchProps {
    onGetAllSystemParam: (selectedTab: number) => any
    onUpdateSystemParam: (sysId: string, value: any) => any
    onReset: (data: any) => any
}


export const TableRow = (props: any) => {
    const { item, selectedParams, onUpdateSystemParam } = props
    const [value, setValue] = useState(selectedParams?.value)
    const [loadingField, setLoadingField] = useState({ [selectedParams.code]: {
        isLoading: false
    }})

    // useEffect(() => {
    //     if (loadingField[selectedParams.code].isLoading === false && props.updateApiStatus.loading === false) {
    //     setValue(selectedParams.value)
    //     }
    // }, [selectedParams.value])

    // useEffect(() => {
    //     if (loadingField[selectedParams.code].isLoading === false && props.updateApiStatus.loading === false) {
    //         setLoadingField({ [selectedParams.code]: { ...loadingField[selectedParams.code], isLoading: false } })
    //     }
    // }, [props.updateApiStatus.loading])
    const onChangeMultiDropdown = (dropdownValue: any) => {
        // const _value: any = value.split('|')
        // if (_value.indexOf(dropdownValue.value) === -1) {
        //     _value.push(dropdownValue)
        // }
        // setValue(_value.join('|'))
        const _value = dropdownValue.map((data: any) => data.value)
        setValue(_value.join('|'))
    }

    const renderFields = (systemField: any) => {
        let field
        switch (systemField.fieldType) {
            case 'Dropdown':
                field = <ArtDropdown itemClassName='systemProperty__items' className='systemProperty__ddl' value={value} items={systemField.list} onClick={setValue} />
                break
            case 'Custom':
                field = <ArtDropdown itemClassName='systemProperty__items' className='systemProperty__ddl' value={value} items={systemField.list} onClick={setValue} />
                break
            case 'MultiDropdown':
                field = <Select styles={{
                    input: (styles) => ({ ...styles, background: '#D9D9D9' }),
                    control: (styles) => ({ ...styles, background: '#D9D9D9', width: 500 })
                 }} options={systemField?.list ?? []} isMulti onChange={onChangeMultiDropdown} value={value ? value.split('|').map((_item: any) => ({ label: _item, value: _item })) : []}/>
                break
            default:
                field = <ArtInput style={{ width: 200 }} type='number' value={value} onChange={(e: any) => setValue(Number(e?.target?.value))} />
        }
        return field
    }

    const onUpdate = () => {
        if (props.item.displayField === 'OTP_RESET') {
            props.onReset({ type: 'all', action: 0 })
            return
        }
        const isSocketReady = Socket.ws?.readyState !== WebSocket.CLOSED ||
        Socket.ws?.readyState !== WebSocket.CLOSING &&
        Socket.ws?.readyState !== WebSocket.CONNECTING

        setLoadingField({ [selectedParams.code]: { ...loadingField[selectedParams.code], isLoading: true } })
        onUpdateSystemParam(selectedParams.id, value)
        .then(() => {
            if (isSocketReady) {
                Socket.sendMessage(JSON.stringify({
                action: 'sendToAll',
                    type: 'updateSystemParams'
                }), props.user?.id)
            }
        })
        .finally(() => {
            setLoadingField({ [selectedParams.code]: { ...loadingField[selectedParams.code], isLoading: false } })
            props.onGetAllSystemParam(props.selectedTab)
        })
    }

    const isLoading = loadingField[selectedParams.code].isLoading
    return (
        <>
            {/* <Modal show contentClassName='updateListing__modalContent'>
                <div>
                    Loading
                </div>
            </Modal> */}
            <tr>
                <td>{item.displayField}</td>
                <td>{renderFields(item)}</td>
                <td><button disabled={item.displayField !== 'OTP_RESET' && selectedParams.value === value} className={classNames('btn', selectedParams?.value !== value || item.displayField === 'OTP_RESET' ? 'activeBtn' :'disabledBtn')} onClick={onUpdate}>{ isLoading ? 'Updating...' : 'Update'}</button></td>
            </tr>
        </>
    )

}

export interface SystemPropertyState {
    selectedTab: number
}

export type SystemPropertyProps = OwnProps & StateProps & DispatchProps

class SystemProperty extends React.Component<SystemPropertyProps, SystemPropertyState> {
    constructor(props: SystemPropertyProps) {
        super(props)
        this.state = {
            selectedTab: 1
        }
    }

    componentDidMount(): void {
        this.props.onGetAllSystemParam(this.state.selectedTab)
        if (Socket.ws?.readyState !== WebSocket.CLOSED ||
            Socket.ws?.readyState !== WebSocket.CLOSING &&
            Socket.ws?.readyState !== WebSocket.CONNECTING) {
            Socket.onMessage((event: any) => {
                console.log('event: ', event)
            }, this.props.user?.id)
        }
    }

    onChangeTab = (value: number) => {
        this.setState({ selectedTab: value }, () => this.props.onGetAllSystemParam(this.state.selectedTab))
    }

    render() {
        return <React.Fragment>
            <ArtHeader />
            <div id='SystemProperty' className='mx-5 systemProperty'>
                <div className='d-flex justify-content-between'>
                    <h3 className='mt-3'>System Properties</h3>
                    <div className='d-flex gap-2'>
                        <button onClick={() => this.onChangeTab(1)} className={classNames('systemProperty__button', { ['systemProperty__button--active']: this.state.selectedTab === 1 })}>System Properties</button>
                        <button onClick={() => this.onChangeTab(2)} className={classNames('systemProperty__button', { ['systemProperty__button--active']: this.state.selectedTab === 2 })}>Mode of Payment</button>
                    </div>
                </div>
                <Table className="systemProperty__container mt-3 table-responsive">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Value</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.systemFields.map((item: any) => {
                            const selectedParams = this.props.systemParams?.find(params => params.code === item.displayField)
                            return selectedParams && item.displayField !== 'OTP_RESET' && <TableRow key={uniqueId()} selectedTab={this.state.selectedTab} onReset={this.props.onReset} user={this.props.user} onGetAllSystemParam={this.props.onGetAllSystemParam} updateApiStatus={this.props.updateApiStatus} item={item} selectedParams={selectedParams} onUpdateSystemParam={this.props.onUpdateSystemParam} />
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const updateParamApi = state.api?.systemParam?.updateSystemParam
    const apiStatus = {
        isLoading: updateParamApi?.loading,
        isError: updateParamApi?.statusText === 'error',
        isSuccess: updateParamApi?.statusText === 'success'
    }
    return {
        systemFields: state.config?.systemFields ?? [],
        systemParams: state.api?.systemParam?.getAllSysParams?.response ?? [],
        updateApiStatus: apiStatus,
        user: state.api?.user?.authUser?.response
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetAllSystemParam: (selectedTab: number) => getAllSystemParams(dispatch, { value: selectedTab, field: 'type' }),
    onReset: (data: any) => updateUserStatus(dispatch, data),
    onUpdateSystemParam: (sysId: string, value: any) => updateSystemParam(dispatch, { value }, sysId)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(SidebarHOC)(SystemProperty as any)))