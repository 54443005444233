import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'

export const BEGIN_UPDATE_SYSTEM_PARAMS = 'API/USER/BEGIN_UPDATE_SYSTEM_PARAMS'
export const SUCCESS_UPDATE_SYSTEM_PARAMS = 'API/USER//SUCCESS_UPDATE_SYSTEM_PARAMS'
export const ERROR_UPDATE_SYSTEM_PARAMS = 'API/USER/ERROR_UPDATE_SYSTEM_PARAMS'
export const CLEAR_UPDATE_SYSTEM_PARAMS = 'API/USER/CLEAR_UPDATE_SYSTEM_PARAMS'

export const updateSystemParamInitialState = {
    response: [],
    loading: false
}

export interface UpdateSystemParamState {
    response: any
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface UpdateSystemParamAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: UpdateSystemParamState = updateSystemParamInitialState, action: UpdateSystemParamAction) => {
    switch (action.type) {
        case BEGIN_UPDATE_SYSTEM_PARAMS:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_UPDATE_SYSTEM_PARAMS:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_UPDATE_SYSTEM_PARAMS:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case CLEAR_UPDATE_SYSTEM_PARAMS:
            return updateSystemParamInitialState
        default:
            return newState
    }
}

export const beginSystemParam = () => ({
    type: BEGIN_UPDATE_SYSTEM_PARAMS,
})

export const successUpdateSystemParam = (response: AxiosResponse) => ({
    type: SUCCESS_UPDATE_SYSTEM_PARAMS,
    data: response.data.payload,
    status: response.status
})

export const errorUpdateSystemParam = (error: AxiosError) => ({
    type: ERROR_UPDATE_SYSTEM_PARAMS,
    status: error.response?.status,
})

export const updateSystemParam = (dispatch: Dispatch, data: any, sysId: string) => {
    const config: AxiosRequestConfig = {
        method: methods.PUT,
        url: `/sysparam/update/${sysId}`,
        data
    }
    dispatch(beginSystemParam())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successUpdateSystemParam(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorUpdateSystemParam(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer