import getFundsReducer, { getFundsInitialState, GetFundsState } from './getFunds'
import updateWalletReducer, { updateWalletInitialState, UpdateWalletState } from './updateWallet'
import { combineReducers } from 'redux'

export interface Wallet {
    id: string,
    balance: number,
    currency: string,
    email: string
}

export const walletInitialState: WalletInitialState = {
    getFunds: getFundsInitialState,
    updateWallet: updateWalletInitialState
}

export interface WalletInitialState {
    getFunds: GetFundsState
    updateWallet: UpdateWalletState
}

const walletReducer = combineReducers({
    getFunds: getFundsReducer,
    updateWallet: updateWalletReducer
})

export default walletReducer

