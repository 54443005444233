import React, { useEffect, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Sidebar from '../../hoc/sidebar'
import { withRouter } from '../../hoc/withRouter'
import { ReduxAppState } from '../../utils/types'
import { getItemList } from '../../reducers/Items/getItems'
import { deleteItem, RESET_DELETE_ITEM } from '../../reducers/Items/deleteItem'
import moment from 'moment'
import { Alert, Modal, Spinner, Table, DropdownButton, Dropdown } from 'react-bootstrap'
import { ArtDropdown, ArtHeader, ArtTooltip } from '../../ReusableComponent'
import * as _ from 'lodash'
import classNames from 'classnames'
import { DropdownValues } from '../../ReusableComponent/ArtDropdown/ArtDropdown'
import UpdateListingStatusModal from '../Modal/UpdateListingStatus'

class Home extends React.Component<any, any> {
    searchRef: React.RefObject<any>
    constructor(props: any) {
        super(props)
        this.state = {
            selectedTooltip: '',
            selectedItem: undefined,
            isDeleteLoading: false,
            searchValue: '',
            fetchedItems: [],
            filterStatus: undefined,
            itemToUpdate: undefined
        }
        this.searchRef = React.createRef()
    }

    componentDidMount() {
        this.props.getItemList(1, { filter: 'type==Active' })
            .then(resp => this.setState({ fetchedItems: resp?.data?.message ? this.defaultSort(resp?.data?.message) : [] }))
    }

    // useEffect(() => {
    //     props.getItemList(1)
    // }, [])

    getStatus = (item: any) => {
        if (item?.data.isPhysicallySold) {
            return 'Sold'
        } else if (item?.data?.status === 1) {
            return 'Inactive'
        } else if (item?.isSoldOut) {
            return 'Sold out'
        } else if (item?.data?.status === 0) {
            if (moment(item.data.listingDate) > moment()) {
                return 'Scheduled'
            }
            return 'Active'
        } else {
            return 'N/A'
        }
    }

    onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchValue: e.target.value
        }, () => this.onSearchArtwork())
    }

    onSearchArtwork = () => this.props.getItemList(1, this.state.searchValue && { filter: `searchTitle>@${this.state.searchValue.toLowerCase()}&type==Active` }).then(resp => this.setState({ fetchedItems: resp?.data?.message ? this.defaultSort(resp?.data?.message) : [] }))

    // const [selectedTooltip, setSelectedTooltip] = useState('')
    // const [selectedItem, setSelectedItem]: any = useState(undefined)
    // const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    // const handleDeleteItem = () => {
    // setIsDeleteLoading(true)
    // props.onDeleteItem(selectedItem?.data?.id).then(() => {
    //     setSelectedItem(undefined)
    //     props.getItemList(1)
    //         .then(() => setIsDeleteLoading(false))
    //         .catch(() => setIsDeleteLoading(false))
    // })
    // .catch(() => setIsDeleteLoading(false))
    // }

    setSelectedItem = (item: any) => this.setState({ selectedItem: item })

    setItemToUpdate = (item: any) => this.setState({ itemToUpdate: item })

    handleDeleteItem = () => {
        this.setState({ isDeleteLoading: true })
        this.props.onDeleteItem(this.state.selectedItem?.data?.id).then(() => {
            setTimeout(() => this.props.onResetDeleteItem(), 10000)
            this.setSelectedItem(undefined)
            this.props.getItemList(1)
                .then(() => this.setState({ isDeleteLoading: false }))
                .catch(() => this.setState({ isDeleteLoading: false }))
        })
            .catch(() => this.setState({ isDeleteLoading: false }))
    }

    setSelectedTooltip = (tooltip: any) => {
        this.setState({ selectedTooltip: tooltip })
    }

    onFilterStatus = (filter: string | undefined) => {
        this.setState({ filterStatus: filter })
    }

    onGetFilteredStatus = (items: any) => {
        return items.filter((item: any) => {
            if ((this.state.filterStatus === 'Active' && item?.data?.status === 0 && moment(item?.data?.listingDate).format('MM-DD-yyyy') < moment().format('MM-DD-yyyy') && !item?.isSoldOut)
                || (this.state.filterStatus === 'Reschedule' && item?.data?.status === 0 && moment(item?.data?.listingDate).format('MM-DD-yyyy') > moment().format('MM-DD-yyyy') && !item?.isSoldOut)
                || (this.state.filterStatus === 'Inactive' && item?.data?.status === 1)
                || (this.state.filterStatus === 'Sold' && item?.isSoldOut && item?.data?.status !== 1)) {
                return true
            }
            return false
        })
    }

    renderHeader = (label: string, sort: boolean = false, onSortUp?: () => any, onSortDown?: () => any) => (
        <>
            <span>{label}</span>
            {sort && onSortUp && onSortDown && <span className='position-relative'>
                <img loading='lazy' src='/Icons/sort-up.png' onClick={onSortUp} className={`position-absolute`} style={{ top: 0 }} />
                <img loading='lazy' src='/Icons/sort-down.png' onClick={onSortDown} className={`position-absolute`} style={{ bottom: 0 }} />
            </span>}
        </>
    )

    onSort = (key: string, isDescending: boolean): any => {
        const _items = [...this.state.fetchedItems]
        console.log('isDescending', isDescending)
        _items.sort((a: any, b: any) => {
            if (_.get(a, key, '') < _.get(b, key, '')) {
                return isDescending ? -1 : 1
            }
            if (_.get(a, key, '') > _.get(b, key, '')) {
                return isDescending ? 1 : -1
            }
            return 0
        })

        this.setState({ fetchedItems: _items })
        return _items
    }

    defaultSort = (_items: any) => {
        _items.sort((a: any, b: any) => {
            if (a?.data?.title?.toUpperCase() < b?.data?.title?.toUpperCase()) {
                return -1
            }
            if (a?.data?.title?.toUpperCase() > b?.data?.title?.toUpperCase()) {
                return 1
            }
            return 0
        })

        return _items
    }

    onDeleteSearch = () => {
        this.searchRef.current.value = ''
        if (this.state.searchValue) this.setState({ searchValue: '' }, () => this.onSearchArtwork())
    }

    onRefreshItem = () => {
        if (this.state.searchValue) {
            this.onSearchArtwork()
        } else {
            this.props.getItemList(1, { filter: 'type==Active' })
                .then(resp => this.setState({ fetchedItems: resp?.data?.message ? this.defaultSort(resp?.data?.message) : [] }))
        }
    }

    render() {
        const _ddlList: DropdownValues[] = [{
            label: 'Clear Filter',
            value: undefined
        }, {
            label: 'Active',
            value: 'Active'
        }, {
            label: 'Inactive',
            value: 'Inactive'
        }, {
            label: 'Sold',
            value: 'Sold'
        }, {
            label: 'Reschedule',
            value: 'Reschedule'
        }]
        const _items = this.state.filterStatus ? this.onGetFilteredStatus(this.state.fetchedItems) : this.state.fetchedItems
        return (
            <React.Fragment>
                <Modal show={!!this.state.selectedItem}>
                    <div className='w-100 text-center p-5'>
                        <div><img loading='lazy' src='/Icons/delete-icon-large.png' width={50} height={50} className="delete-icon mt-1" /></div>
                        <p className='my-2'><FormattedMessage id='Home.warning.delete' /></p>
                        <div className="d-flex mx-5 gap-5">
                            <button disabled={this.state.isDeleteLoading} onClick={this.handleDeleteItem} className="btn w-50" style={{ background: '#F68C29' }}>{this.state.isDeleteLoading ? <Spinner animation='border' size='sm' /> : 'Yes'}</button>
                            <button disabled={this.state.isDeleteLoading} onClick={() => this.setSelectedItem(undefined)} className="btn btn-secondary w-50">No</button>
                        </div>
                    </div>
                </Modal>
                <UpdateListingStatusModal onRefreshItem={this.onRefreshItem} item={this.state.itemToUpdate} isOpen={!!this.state.itemToUpdate} setItemToUpdate={this.setItemToUpdate} />
                <ArtHeader />
                {this.props.isUpdateListingSuccess && <Alert variant='success mx-5'>Item updated successfully!</Alert>}
                {this.props.addedItem?.statusText === 'success' && <Alert variant='success mx-5 mt-2'><FormattedMessage id='AddArtListing.success.addListing' /></Alert>}
                {this.props.deletedItem?.statusText === 'success' && <Alert variant='success mx-5 mt-2'><FormattedMessage id='Home.success.delete' /></Alert>}
                <div className='d-flex justify-content-between align-items-center mb-3 mt-5 mx-5'>
                    <p className='title'><FormattedMessage id='Home.label.artListing' /></p>
                    <div className="d-flex gap-5">
                        <p onClick={() => this.props.router?.navigate('/listing/add')} className='add-button d-flex gap-3' style={{ borderRadius: 11 }}><span><i className="fa fa-plus-circle"></i></span><FormattedMessage id='Home.label.addNewArtListing' /></p>
                        <div className='position-relative'>
                            <input
                                className={'search-field'}
                                type={'text'}
                                onChange={_.debounce(this.onChangeSearch, 500)}
                                ref={this.searchRef}
                                style={{ borderRadius: 11 }}
                            />
                            <span onClick={this.onDeleteSearch} className='search-icon'><i className={classNames('fa', this.state.searchValue ? 'fa-times' : 'fa-search')}></i></span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row mx-5 table-container">
                    <Table className="amtable table-bordered rounded" style={{ height: '100%', padding: 0 }}>
                        <thead style={{ position: 'relative' }}>
                            <tr>
                                <th className="column status-column">
                                    <ArtDropdown title='Status' value={this.state.filterStatus} items={_ddlList} onClick={this.onFilterStatus} className='statusDdl' />
                                </th>
                                <th className="column">{this.renderHeader('Artwork Name')}</th>
                                <th className="column">{this.renderHeader('Total Share', true, () => this.onSort('data.numberOfShare', false), () => this.onSort('data.numberOfShare', true))}</th>
                                <th className="column">{this.renderHeader('Shares Sold', true, () => this.onSort('sharesSold', false), () => this.onSort('sharesSold', true))}</th>
                                <th className="column">{this.renderHeader('Available Shares', true, () => this.onSort('availableShare', false), () => this.onSort('availableShare', true))}</th>
                                <th className="column">{this.renderHeader('Listing Date', true, () => this.onSort('data.listingDate', false), () => this.onSort('data.listingDate', true))}</th>
                                <th className="column">{this.renderHeader('Sale Date', true, () => this.onSort('data.saleDate', false), () => this.onSort('data.saleDate', true))}</th>
                                <th className='column' />
                                <th className='column' />
                            </tr>
                        </thead>
                        <tbody style={{ position: 'relative', height: this.props.itemList?.loading && '5rem' }}>
                            {this.props.itemList?.loading ? (
                                <Spinner animation='border' size='sm' className='am-spinner' />
                            ) : _items && _items.length > 0 ? _items.map((item: any) => (
                                <tr>
                                    {/* // <tr onClick={() => this.props.router?.navigate(`/listing/${item.data.id}/edit`)}> */}
                                    <td className='position-relative am-pointer' onClick={() => !item?.data?.isPhysicallySold && this.setItemToUpdate(item)}>{this.getStatus(item)} <img className='position-absolute' style={{ right: 15, top: 20 }} width={8} height={7} loading='lazy' src='/Icons/sort-down.png' /></td>
                                    <td>{item.data.title}</td>
                                    <td><FormattedNumber style='decimal' value={item.data.numberOfShare * Number(process.env.VALUE_PER_SHARE) ?? 0} /></td>
                                    <td><FormattedNumber style='decimal' value={item.sharesSold} /></td>
                                    <td><FormattedNumber style='decimal' value={item.availableShare ?? 0} /></td>
                                    <td>{moment(item.data.listingDate).format('MM/DD/yyyy').toString()}</td>
                                    <td>{moment(item.data.saleDate).format('MM/DD/yyyy').toString()}</td>
                                    <td>
                                        <span style={{ cursor: 'pointer' }} onClick={() => this.props.router?.navigate(`/analytics/${item.data.id}`)}><img loading='lazy' src='/Icons/expand-icon.png' width={15} height={15} className="mt-1" /></span>
                                    </td>
                                    <td className='three-dot positiove-relative'>
                                        <ArtTooltip trigger='click' renderTooltip={() => (
                                            <div className='home-tooltip__container'>
                                                {/* <button title={item.data?.status === 1 ? 'Deleting an inactive item is not allowed.' : ''} disabled={item.data?.status === 1} onClick={() => this.setSelectedItem(item)} className="am-tooltip d-flex justify-content-evenly"><img loading='lazy' src='/Icons/delete-icon.png' width={15} height={15} className="delete-icon mt-1" /> <span className='ml-3'><FormattedMessage id='Home.button.delete' /></span></button> */}
                                                {this.getStatus(item) === 'Active' && <button onClick={() => this.props.router?.navigate(`/listing/${item.data.id}/edit`)} className="home-tooltip d-flex justify-content-evenly"><span className='ml-3'>Edit</span></button>}
                                                <button title={item.data?.status === 1 ? 'Deleting an inactive item is not allowed.' : ''} disabled={item.data?.status === 1} onClick={() => this.setSelectedItem(item)} className="home-tooltip d-flex justify-content-evenly"><img loading='lazy' src='/Icons/delete-icon.png' width={15} height={15} className="delete-icon mt-1" /> <span className='ml-3'><FormattedMessage id='Home.button.delete' /></span></button>
                                                {/* <button title={item.data?.status === 1 && 'Deleting an inactive item is not allowed.'} disabled={item.data?.status === 1} onClick={() => this.setSelectedItem(item)} className="am-tooltip d-flex justify-content-evenly"><img loading='lazy' src='/Icons/delete-icon.png' width={15} height={15} className="delete-icon mt-1" /> <span className='ml-3'><FormattedMessage id='Home.button.delete' /></span></button> */}
                                            </div>
                                        )}><span className='three-dot-text' onClick={() => this.setSelectedTooltip(this.state.selectedTooltip === item.data.id ? '' : item.data.id)}>...</span></ArtTooltip>
                                        {/* <div className="tooltip"> */}
                                        {/* {selectedTooltip === item.data.id && <button disabled={item.data?.status === 1} onClick={() => setSelectedItem(item)} className="am-tooltip d-flex justify-content-evenly"><img loading='lazy' src='/delete-icon.png' width={15} height={15} className="delete-icon mt-1" /> <span>Delete</span></button>} */}
                                        {/* </div> */}
                                    </td>
                                </tr>
                            )) : <>
                                <tr><div><FormattedMessage id='Home.label.noDataFound' /></div></tr>
                            </>}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser,
        itemList: state.api?.items?.getItemList,
        addedItem: state.api?.items?.addItem,
        deletedItem: state.api?.items?.deleteItem,
        isUpdateListingSuccess: state.api.items?.updateListing?.statusText === 'success'
        // errorMsg: state.api?.user?.authUser?.error,
        // isSignedIn: state.api?.user?.authUser?.isSignedIn,
        // isLoading: state.api?.user.authUser.loading,
        // isError: state.api?.user.authUser.statusText === 'error',
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getItemList: ((isPosted: number, otherOption: any = {}) => getItemList(dispatch, { isPosted, type: 'all', ...otherOption })),
    onDeleteItem: (id: string) => deleteItem(dispatch, id),
    onResetDeleteItem: () => dispatch({ type: RESET_DELETE_ITEM })
    // loginUser: (username: string, password: string) =>
    //     loginUser(dispatch, { username, password })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(Sidebar)(Home as any)))
