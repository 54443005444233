import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Analytics } from '.'


export const BEGIN_GET_ANALYTICS = 'API/ITEM/BEGIN_GET_ANALYTICS'
export const SUCCESS_GET_ANALYTICS = 'API/ITEM/SUCCESS_GET_ANALYTICS'
export const ERROR_GET_ANALYTICS = 'API/ITEM/ERROR_GET_ANALYTICS'

export const getAnalyticsInitialState = {
    response: undefined,
    loading: false,
}

export interface GetAnalyitcsState {
    response: Analytics[] | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
    imageUrl?: any
}

export interface GetAnalyticsActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetAnalyitcsState = getAnalyticsInitialState, action: GetAnalyticsActions) => {
    switch (action.type) {
        case BEGIN_GET_ANALYTICS:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ANALYTICS:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_ANALYTICS:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetAnaylitics = () => ({
    type: BEGIN_GET_ANALYTICS,
})

export const successGetAnalytics = (response: AxiosResponse) => ({
    type: SUCCESS_GET_ANALYTICS,
    data: response.data.message,
    status: response.status
})

export const errorGetAnalytics = (error: AxiosError) => ({
    type: ERROR_GET_ANALYTICS,
    status: error.response?.status,
})

export const getAnalytics = (dispatch: Dispatch, params: any) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/analytics`,
        params,
    }
    dispatch(beginGetAnaylitics())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetAnalytics(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetAnalytics(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer