import { Routes, SubRoutes } from "../../utils/routes"

export const UPDATE_ACTIVE_MENU = 'API/ACTIVE_MENU/UPDATE_ACTIVE_MENU'

export const activeMenuInitialState = {
    activeLink: Routes.DIRECTORY,
    activeSubLink: SubRoutes.ART_LISTING,
    loading: false
}

export interface ActiveMenuState {
    activeLink: string,
    activeSubLink: string,
}

export interface ActiveMenuActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: ActiveMenuState = activeMenuInitialState, action: ActiveMenuActions) => {
    switch (action.type) {
        case UPDATE_ACTIVE_MENU:
            return Object.assign({}, newState, {
                loading: false,
                activeLink: action.link,
                activeSubLink: action.sublink
            })
        default:
            return newState
    }
}

export const updateMenu = (link: string, sublink: string) => ({
    type: UPDATE_ACTIVE_MENU,
    link,
    sublink
})

export default reducer