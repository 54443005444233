import React from 'react'

import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ReduxAppState } from '../../utils/types'
import { withRouter } from '../../hoc/withRouter'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { generateRandomId } from '../../utils'
import { Spinner, Table } from 'react-bootstrap'
import { Items } from '../../reducers/Items'
import { getAuditLogs } from '../../reducers/Audit/getAuditLogs'
import { Audit } from '../../reducers/Audit'
import moment from 'moment';

export interface TranHistoryPageProps {
    router: any
}

export interface StateProps {
    tranHistory?: any,
    isError: boolean
}

export interface DispatchProps {
    getTranHistory: (id: string) => any
}

export interface TranHistoryPageState {
    selectedTooltip: string
}

export type TranHistoryProps = TranHistoryPageProps & StateProps & DispatchProps

class TranHistoryPage extends React.Component<TranHistoryProps, TranHistoryPageState> {
    constructor(props: TranHistoryProps) {
        super(props)
        this.state = {
            selectedTooltip: ''
        }
    }

    componentDidMount(): void {
        const userId = this.props.router?.params?.id
        this.props.getTranHistory(userId)
    }

    getArtworkName = (operation: string) => {
        const str = operation
        const n = 2
        const a = str.split(' ')
        const artworkName = a.slice(n).join(' ')
        return artworkName
    }

    getOperationName = (operation: string) => {
        const str = operation
        const operationName = str.split(' ')[0]
        return operationName
    }


    render() {
        const _transactions = this.props.tranHistory?.response ?? []
        return (
            <React.Fragment>
                <div className="d-flex flex-row mx-5 portfolio-table-container ">
                    {this.props.isError && <p><FormattedMessage id='TranHistory.msg.somethingWentWrong' /></p>}
                    <Table className="amtable">
                        <thead>
                            <tr>
                                <th className="column"><span><FormattedMessage id='TranHistory.label.transactionDate' /></span></th>
                                <th className="column"><FormattedMessage id='TranHistory.label.operation' /></th>
                                <th className="column"><FormattedMessage id='TranHistory.label.transactionId' /></th>
                                <th className="column"><FormattedMessage id='TranHistory.label.runningBalance' /></th>
                                <th className="column"><FormattedMessage id='TranHistory.label.amount' /></th>
                                <th className="column"><FormattedMessage id='TranHistory.label.newBalance' /></th>
                                <th className="column"><FormattedMessage id='TranHistory.label.remarks' /></th>
                            </tr>
                        </thead>
                        <tbody style={{ position: 'relative', height: this.props.tranHistory?.loading && '5rem' }}>
                            {this.props.tranHistory?.loading ? (
                                <Spinner animation='border' size='sm' className='am-spinner' />
                            ) : _transactions && _transactions.length > 0 ? _transactions.map((item: Audit) => (
                                <tr
                                    key={generateRandomId()}
                                >
                                    <td>{moment(item?.tranDate).format('MM/DD/YYYY h:mm:ss a')}</td>
                                    <td>{item?.tranType}</td>
                                    <td>{item?.tranId}</td>
                                    <td><FormattedNumber format={'USD'} value={Number(item?.runningBalance ?? 0)} /></td>
                                    <td><FormattedNumber format={'USD'} value={Number(item?.amt ?? 0)} /></td>
                                    <td><FormattedNumber format={'USD'} value={Number(item?.balance ?? 0)} /></td>
                                    <td>{this.getArtworkName(item?.operation)}</td>
                                </tr>
                            )) : <div>No data found</div>}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _getTranHistory = state.api?.audit?.getAuditLogs
    return {
        tranHistory: state.api?.audit?.getAuditLogs,
        isError: _getTranHistory?.statusText === 'error',
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTranHistory: (userId: string) => getAuditLogs(dispatch, userId)
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TranHistoryPage as any))
