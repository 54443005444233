
import authUserReducer, { acctInfoInitialState, AccountInfoInitialState } from './User'
import itemReducer, { itemInitialState, ItemInitialState } from './Items'
import menuReducer, { menuInitialState, MenuInitialState } from './Menu'
import auditReducer, { auditInitialState, AuditInitialState } from './Audit'
import walletReducer, { walletInitialState, WalletInitialState } from './Wallet'
import analyticsReducer, { analyticsInitialState, AnalyticsInitialState } from './Analytics'
import systemParamReducer, { systemPropertiesInitialState, SystemPropertiesIntialState } from './SystemProperty'

import { combineReducers } from 'redux'

export const apiInitialState: ApiInitialState = {
    user: acctInfoInitialState,
    items: itemInitialState,
    menu: menuInitialState,
    audit: auditInitialState,
    wallet: walletInitialState,
    analytics: analyticsInitialState,
    systemParam: systemPropertiesInitialState
}

export interface ApiInitialState {
    user: AccountInfoInitialState
    items: ItemInitialState
    menu: MenuInitialState
    audit: AuditInitialState
    wallet: WalletInitialState
    analytics: AnalyticsInitialState
    systemParam: SystemPropertiesIntialState
}

const reducer = combineReducers({
    user: authUserReducer,
    items: itemReducer,
    menu: menuReducer,
    audit: auditReducer,
    wallet: walletReducer,
    analytics: analyticsReducer,
    systemParam: systemParamReducer
})

export default reducer