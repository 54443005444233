export enum Routes {
    DIRECTORY = 'Directory',
    BUSINESS_OWNER = 'BusinessOwner',
    EMPLOYEE = 'Employee',
    SYSTEM_PROPERTY = 'SystemProperty',
    LOGOUT = 'Logout'
}

export enum SubRoutes {
    ART_LISTING = 'ArtListing',
    ACCOUNT_LISTING = 'AccountListing',
    ADD_ART_LISTING = 'AddArtListing'
}

export enum UserTab {
    ACCOUNT_HISTORY = 'Account History',
    PORTFOLIO = 'Portfolio',
    TRAN_HISTORY = 'Transaction'
}