export const userStatus = [
    {
        value: 0,
        label: 'Active'
    },
    {
        value: 1,
        label: 'Blocked'
    },
    {
        value: 2,
        label: 'Dormant'
    },
]