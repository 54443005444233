import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import * as yup from 'yup'
import SidebarHOC from '../../hoc/sidebar'
import { withRouter } from '../../hoc/withRouter'
import { ArtDatePicker, ArtForm, ArtHeader } from '../../ReusableComponent'
import ArtField, { ArtFieldsType } from '../../ReusableComponent/Form/ArtFields'
import { ReduxAppState } from '../../utils/types'
import classNames from 'classnames'
import { getItemList } from '../../reducers/Items/getItems'
import { Items } from '../../reducers/Items'
import _ from 'lodash'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { Navigate } from 'react-router'
import { Alert, Spinner } from 'react-bootstrap'
import { RESET_UPDATE_LISTING, updateListing } from '../../reducers/Items/updateListing'

export interface OwnProps {
    router: any
}

export interface StateProps {
    item: Items
    itemStatus: any
    isLoadingUpdate: boolean
    isErrorUpdate: boolean
}

export interface DispatchProps {
    onGetItem: (itemId: string) => any
    onUpdateListing: (itemId: string, data: any) => any
    onClearUpdateListing: () => void
}

export type EditListingProps = OwnProps & StateProps & DispatchProps

const FormSchema = yup.object({
    title: yup.string().required('Artwork is required'),
    artist: yup.string().required('Artist is required'),
    saleDate: yup.string().required('Sale Date is required'),
    dmt: yup.string().required('Dimensions, Material and Technique is required'),
    description: yup.string().required('Description is required'),
    conditionReportId: yup.mixed(),
    selectedFiles: yup.mixed(),
    photoFileId: yup.mixed(),
    authenticationDetailsFileId: yup.mixed(),
})

const EditListing = (props: EditListingProps) => {
    useEffect(() => {
        reset()
        const _itemId = _.get(props, 'router.params.itemId')
        props.onGetItem(_itemId)
    }, [props?.router?.params?.itemId])
    const _artWorkCover: any = useRef()
    const _authDetails: any = useRef()
    const _conditionReport: any = useRef()
    const _otherPhotos: any = useRef()

    const [files, setFiles] = useState([])

    const [artworkCover, setArtWorkCover] = useState(undefined)
    const [conditionReport, setConditionReport] = useState(undefined)
    const [authenticationDetails, setAuthenticationDetails] = useState(undefined)
    const [otherImage, setOtherImage] = useState(undefined)

    const handleFile = (file: any, setFile: any, key: string) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
            if (key === 'artWorkCover') {
                setArtWorkCover(e.target.result)
            }
            if (key === 'authenticationDetails') {
                setAuthenticationDetails(e.target.result)
            }
            if (key === 'conditionReport') {
                setConditionReport(e.target.result)
            }
            setFile({
                fileName: file.name,
                decodedFile: e?.target?.result?.split(',')[1],
                type: file.type
            })
        }
        reader.readAsDataURL(file)
    }

    const multipleFileSelectedHandler = (e: any, onChange: any) => {
        if (e.target.files) {
            const imgArr: any = []
            const filesArray = Array.from(e.target.files).map((image: any) => {
                getBase64(image, (result: any) => {
                    imgArr.push(result)
                    setFiles([...files,
                    {
                        fileName: image?.name,
                        decodedFile: result.split(',')[1],
                        type: image?.type
                    }])
                })
                return URL.createObjectURL(image)
            })
            setOtherImage(imgArr)
            onChange(filesArray)
        }
    }

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            cb(reader.result)
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }
    const { isSuccess, isError, isLoading } = props.itemStatus


    const _onSubmit = (data: any) => {
        const _itemId = _.get(props, 'router.params.itemId')
        data['saleDate'] = Date.parse(new Date(String(data.saleDate)).toString())
        data['searchTitle'] = data.title.toLowerCase()
        data['searchArtist'] = data.artist.toLowerCase()
        props.onUpdateListing(_itemId, data)
        .then(() => {
            reset()
            props?.router?.navigate(`/listing`)
        })
        .finally(() => {
            setTimeout(props.onClearUpdateListing, 10000)
        })
    }

    const { Form, onSubmit, control, handleSubmit, reset } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit, otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })
    return (
        <React.Fragment>
            <ArtHeader />
            {props.isErrorUpdate && <Alert variant='danger'>Something went wrong. Please Try again</Alert>}
            {/* {isError && <Navigate to={'/listing'} />} */}
            {isLoading ? <Spinner animation='border' size='sm' className='am-spinner' /> : (
                <Form id='EditListing' className='editListingForm d-flex m-5 justify-content-between' onSubmit={onSubmit}>
                    <div>
                        <h3 className='mb-3'>EDIT ART LISTING</h3>
                        <div className="am-ml__3 d-flex flex-column gap-4">
                            <ArtField required id='title' defaultValue={props.item?.title} name='title' type='text' label='Artwork Title' fieldstype={ArtFieldsType.ArtInput} control={control} />
                            <ArtField required id='artist' defaultValue={props.item?.artist} name='artist' type='text' label='Artist' fieldstype={ArtFieldsType.ArtInput} control={control} />
                            <ArtField required id='dmt' defaultValue={props.item?.dmt} name='dmt' type='text' label='Dimension/Materials' fieldstype={ArtFieldsType.ArtInput} control={control} />
                            <ArtField id='saleDate' defaultValue={String(props.item?.saleDate ?? '')} name='saleDate' fieldstype={ArtFieldsType.CustomField} control={control} renderField={(onChange: any, value: any, error?: any) => (
                                <div>
                                    <label>Sale Date</label>
                                    <ArtDatePicker value={String(props.item?.saleDate ?? '')} placement='top-start' onChange={onChange} advanceYear={5} showCalendarIcon placeholder='(MM/DD/YYYY)' inputClassName='w-100' className={classNames(!!error && 'border border-danger rounded', 'w-100')} />
                                </div>
                            )} />
                            <ArtField id='description' defaultValue={props.item?.description} name='description' type='textarea' label='Description' className='' fieldstype={ArtFieldsType.ArtTextArea} control={control} />
                        </div>
                        <h3 className='mt-5 mb-3'>ATTACHMENT</h3>
                        <div className="am-ml__3 d-flex flex-column gap-4">
                            <ArtField control={control} id='photoFileId' name='photoFileId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                                <div>
                                    <div>
                                        <label><FormattedMessage id='AddArtListing.label.artworkCover' /></label>
                                        <div className={classNames('am-upload', !!error && 'border border-danger')}>
                                            <button type='button' className='am-upload__button' onClick={() => _artWorkCover?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                            {(artworkCover) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                            <input type='file' className='d-none' ref={_artWorkCover} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'artWorkCover')} />
                                            <ArtField control={control} id='selectedFiles' name='selectedFiles' fieldstype={ArtFieldsType.CustomField} renderField={(_onChange: any) => (
                                                <>
                                                    <input type='file' multiple className='d-none' ref={_otherPhotos} onChange={e => multipleFileSelectedHandler(e, _onChange)} />
                                                    <button type='button' className='am-upload__button am-upload__button--link' onClick={() => _otherPhotos?.current?.click()}><FormattedMessage id='AddArtListing.label.attachOtherPhotos' /></button>
                                                    {((files && files.length > 0)) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                                </>
                                            )} />
                                        </div>
                                    </div>
                                </div>
                            )} />
                            <div>
                                <label><FormattedMessage id='AddArtListing.label.authenticationDetails' /></label>
                                <ArtField control={control} id='authenticationDetailsFileId' name='authenticationDetailsFileId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                                    <div className="am-upload">
                                        <button type='button' className='am-upload__button' onClick={() => _authDetails?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                        {(authenticationDetails) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                        <input type='file' className='d-none' ref={_authDetails} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'authenticationDetails')} />
                                    </div>
                                )} />
                            </div>
                            <div>
                                <label><FormattedMessage id='AddArtListing.label.conditionReport' /></label>
                                <ArtField defaultValue={''} control={control} id='conditionReportId' name='conditionReportId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                                    <div className={classNames('am-upload', !!error && 'border border-danger')}>
                                        <button type='button' className='am-upload__button' onClick={() => _conditionReport?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                        {conditionReport && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                        <input type='file' className='d-none' ref={_conditionReport} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'conditionReport')} />
                                    </div>
                                )} />
                            </div>
                        </div>
                    </div>
                    <div style={{ maxHeight: '90vh', width: '727px' }}>
                        <img src={artworkCover ?? props.item?.imageUrl} width='100%' height={'90%'} />
                        <div className="d-flex flex-row justify-content-end">
                            <button disabled={props.isLoadingUpdate} onClick={() => props?.router?.navigate('/listing')} type='button' className='am-upload__button' style={{ width: 94 }}><FormattedMessage id='AddArtListing.button.cancel' /></button>
                            <button disabled={props.isLoadingUpdate} onClick={onSubmit} type='submit' className='am-upload__button am-upload__button--submit' style={{ width: 94 }}>{props.isLoadingUpdate ? <Spinner animation='border' size='sm' /> : <FormattedMessage id='AddArtListing.button.ok' />}</button>
                        </div>
                    </div>
                </Form>
            )}
        </React.Fragment>
    )
}


const mapStateToProps = (state: ReduxAppState) => {
    const itemList = state.api?.items?.getItemInfo
    const item = itemList?.response
    const itemStatus = {
        isLoading: itemList?.loading,
        isSuccess: itemList?.statusText === 'success',
        isError: itemList?.statusText === 'error'
    }
    return {
        user: state.api?.user?.authUser,
        item,
        itemStatus,
        isLoadingUpdate: state.api.items?.updateListing?.loading,
        isErrorUpdate: state.api.items?.updateListing?.statusText === 'error',
        // errorMsg: state.api?.user?.authUser?.error,
        // isSignedIn: state.api?.user?.authUser?.isSignedIn,
        // isLoading: state.api?.user.authUser.loading,
        // isError: state.api?.user.authUser.statusText === 'error',
        isError: state.api?.items?.addItem.statusText === 'error'
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetItem: (itemId: string) => getItemInfo(dispatch, itemId),
    onUpdateListing: (itemId: string, data: any) => updateListing(dispatch, data, itemId),
    onClearUpdateListing: () => dispatch({ type: RESET_UPDATE_LISTING })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(SidebarHOC)(EditListing as any)))