import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Items } from '.'

export const BEGIN_UPDATE_LISTING = 'API/ITEM/BEGIN_UPDATE_LISTING'
export const SUCCESS_UPDATE_LISTING = 'API/ITEM/SUCCESS_UPDATE_LISTING'
export const ERROR_UPDATE_LISTING = 'API/ITEM/ERROR_UPDATE_LISTING'
export const RESET_UPDATE_LISTING = 'API/ITEM/RESET_UPDATE_LISTING'

export const updateListingInitialState = {
    response: undefined,
    loading: false,
}

export interface UpdateListingActions {
    type: string
    [item: string]: any
}

export interface UpdateListingState {
    response: Items | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any,
}

export const reducer = (newState: UpdateListingState = updateListingInitialState, action: UpdateListingActions) => {
    switch (action.type) {
        case BEGIN_UPDATE_LISTING:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_UPDATE_LISTING:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_UPDATE_LISTING:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status,
                error: action.message
            })

        case RESET_UPDATE_LISTING:
            return updateListingInitialState
        default:
            return newState
    }
}

export const beginUpdateListing = () => ({
    type: BEGIN_UPDATE_LISTING,
})

export const successUpdateListing = (response: AxiosResponse) => ({
    type: SUCCESS_UPDATE_LISTING,
    data: response.data.message,
    status: response.status
})

export const errorUpdateListing = (error: AxiosError) => ({
    type: ERROR_UPDATE_LISTING,
    status: error.response?.status,
    message: error.response?.data.message,
})

export const updateListing = (dispatch: Dispatch, data: any, itemId: string) => {
    const config: AxiosRequestConfig = {
        method: methods.PUT,
        url: `/item/${itemId}`,
        data
    }
    dispatch(beginUpdateListing())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successUpdateListing(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorUpdateListing(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer