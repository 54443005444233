import React from 'react'
import { Navigate } from 'react-router'
import { withRouter } from '../../hoc/withRouter'
import moment from 'moment'
import { connect } from 'react-redux'
import { addItem, RESET_ADD_ITEM } from '../../reducers/Items/addItem'
import { Dispatch } from 'redux'
import { ReduxAppState } from '../../utils/types'
import ImageGallery from 'react-image-gallery'
import _ from 'lodash'
import { Alert, Spinner } from 'react-bootstrap'
import { ArtHeader } from '../../ReusableComponent'
import { FormattedMessage } from 'react-intl'

class PreviewPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            returnListing: false,
            fullscreen: false
        }
    }

    onSetReturnListing = (isReturn: boolean) => this.setState({ returnListing: isReturn })

    convertDateToUnixTime = (date: string) => {
        const dateParts = date.split("-")
        const dateObject = new Date(+dateParts[2], +dateParts[0] - 1, +dateParts[1])
        const dateToConvert = dateObject.toString()

        return Date.parse(dateToConvert)
    }

    _onSubmit = () => {
        const _state = this.props?.router?.location?.state
        const submitPurpose = 'POST'
        const _data = {
            itemAttribute: {
                ..._state,
                userId: this.props.user?.id,
                isPosted: 1,
                numberOfShare: Math.floor(_state.listedValue / Number(process.env.VALUE_PER_SHARE)),
                listingDate: Date.parse(new Date(String(_state.listingDate)).toString()),
                saleDate: Date.parse(new Date(String(_state.saleDate)).toString())
            },
            authenticationDetailsFileId: _state?.authenticationDetailsFileId,
            factSheetFileId: _state?.factSheetFileId,
            conditionReportId: _state?.conditionReportId,
            photoFileId: _state?.photoFileId
        }
        this.props.addItem({ submitPurpose, ..._data, files: _state?.files })
            .then(() => {
                this.props.router?.navigate('/listing')
                setTimeout(() => this.props.resetAddItem(), 15000)
            })
            .catch(() => {
                setTimeout(() => this.props.resetAddItem(), 15000)
            })
    }

    render() {
        console.log(this.props)
        const _state = this.props?.router?.location?.state
        console.log(_state?.artworkCover ?? '/img-placeholder.png')
        const _otherImages = _state?.otherImage && _.isArray(_state?.otherImage) && _state?.otherImage?.length > 0 ? _state?.otherImage.map((item: any) => ({
            original: item,
            thumbnail: item,
            originalHeight: !this.state.fullscreen && 700
        })) : []
        return (
            <div className='PreviewPageComponent'>
                <ArtHeader />
                {this.props.isError && <Alert variant='danger mx-5 mt-2'><FormattedMessage id='PreviewPage.error.addListing' /></Alert>}
                {this.state.returnListing && <Navigate to='/listing/add' state={{ ..._state }} />}
                <div className="renderPreviewPage d-flex justify-content-between">
                    <button onClick={() => this.onSetReturnListing(true)} className='return-btn am-upload__button am-upload__button--submit mt-5 d-flex gap-2'><span><i className='fa fa-arrow-left' /></span>Return</button>
                    <button onClick={this._onSubmit} disabled={this.props.isLoading} type='button' className='a-btn preview-btn'>{this.props.isLoading ? <Spinner animation='border' size='sm' /> : (
                        <><span><i className="fa fa-plus-circle"></i></span><span><FormattedMessage id='PreviewPage.label.addListing' /></span></>
                    )}</button>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="preview-page__image-container mt-5">
                        <ImageGallery
                            onScreenChange={(fullscreen: boolean) => this.setState({ fullscreen })}
                            items={[{
                                original: _state?.artworkCover,
                                thumbnail: _state?.artworkCover,
                                originalHeight: !this.state.fullscreen && 700,
                            }, ..._otherImages]}
                        />
                        {/* <img loading='lazy' src={_state?.artworkCover ?? '/Templates/img-placeholder.png'} width={'100%'} height={'100%'} alt='no-image' className='mt-5' /> */}
                    </div>
                    <div className="preview-page__details-container mt-5 w-25">
                        <div className="d-flex gap-5 mb-5">
                            <p className='rectangle-value d-flex flex-column justify-content-center'><span><FormattedMessage id='PreviewPage.label.value' /></span><span>{_state.listedValue}</span></p>
                            <p className='rectangle-value d-flex flex-column justify-content-center'><span><FormattedMessage id='PreviewPage.label.saleDate' /></span><span>{moment(_state?.saleDate).format('MM-DD-yyyy').toString()}</span></p>
                            <p className='rectangle-value d-flex flex-column justify-content-center'><span><FormattedMessage id='PreviewPage.label.shareValue' /></span><span>$10</span></p>
                        </div>
                        <div className="d-flex flex-column gap-5 mb-5 ">
                            <div className="d-flex flex-column gap-1">
                                <h3 className='text-uppercase'>{_state?.title}</h3>
                                <p className='text-capitalize'>{_state?.artist}</p>
                                <div className='border-bottom border-dark' />
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <h3><FormattedMessage id='PreviewPage.label.dmt' /></h3>
                                <p className='text-capitalize'>{_state?.dmt}</p>
                                <div className='border-bottom border-dark' />
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <h3><FormattedMessage id='PreviewPage.label.dmt' /></h3>
                                <p className='text-capitalize'>{_state?.description}</p>
                                <div className='border-bottom border-dark' />
                            </div>
                        </div>
                        <h3><FormattedMessage id='PreviewPage.label.displayLocation' /></h3>
                        <p>{_state?.displayLocation}</p>
                        <div className="files d-flex flex-column justify-content-center mx-5 gap-3 mt-5">
                            <a className='a-btn' href='mailto:support@artmutual.io?subject=Art Share'><FormattedMessage id='PreviewPage.label.authenticationDetails' /></a>
                            <a className='a-btn' href={_state?.conditionReport} download><FormattedMessage id='PreviewPage.label.conditionReport' /></a>
                            <a className='a-btn' href={_state?.factSheet} download><FormattedMessage id='PreviewPage.label.factSheet' /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser,
        itemList: state.api?.items?.getItemList,
        // errorMsg: state.api?.user?.authUser?.error,
        // isSignedIn: state.api?.user?.authUser?.isSignedIn,
        // isLoading: state.api?.user.authUser.loading,
        // isError: state.api?.user.authUser.statusText === 'error',
        isError: state.api?.items?.addItem.statusText === 'error',
        isLoading: state.api?.items?.addItem?.loading
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addItem: (data: any) => addItem(dispatch, data),
    resetAddItem: () => dispatch({ type: RESET_ADD_ITEM })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((PreviewPage as any)))