import React from 'react'

import { FormattedMessage } from 'react-intl'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { CLEAR_USER, loginUser } from '../../reducers/User/user'
import { ReduxAppState } from '../../utils/types'
import { withRouter } from '../../hoc/withRouter'
import * as _ from 'lodash'

export interface LoginPageProps {
    router: any
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
    errorMsg: string
}

export interface DispatchProps {
    loginUser: (username: string, password: string) => any,
    onClearUser: () => any
}

export interface UserDetailState {
    value: any
    emailError: string
    passwordError: string
    adminSignInError: string
    isSubmit: boolean
    isShowPassword: boolean
}

export const notRequiredFields = []

export type UserLoginDetailsProps = LoginPageProps & StateProps & DispatchProps

// const FormSchema = yup.object({
//     username: yup.string()
//         .required('Username is required')
//         .matches(/^\s*\S[\s\S]*$/, 'Username is required'),
//     password: yup.string()
//         .required('Password is required')
//         .matches(/^(?!\s+$).*/, 'Password is required'),
// })

class LoginPage extends React.Component<UserLoginDetailsProps, UserDetailState> {
    constructor(props: UserLoginDetailsProps) {
        super(props)
        this.state = {
            value: {
                username: '',
                password: ''
            },
            emailError: '',
            passwordError: '',
            adminSignInError: 'Not an admin account',
            isSubmit: false,
            isShowPassword: false
        }
    }

    componentDidMount(): void {
        this.props.onClearUser()
    }

    onChangeValue = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        const _value = { ...this.state.value }
        _value[key] = newValue.replace(/\s/g, "")
        this.setState({ value: _value })

    }

    onLoginPressed = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        this.props.onClearUser()
        const _value = { ...this.state.value }
        let isSuccessValidation: boolean = true
        _.forIn(_value, (value: any, key: any) => {
            if (!Boolean(value)) {
                isSuccessValidation = Boolean(notRequiredFields.find((item: string) => item === key))
            }
        })

        this.setState({ isSubmit: true })
        const { username, password } = this.state.value
        if (isSuccessValidation) {
            this.props.loginUser(username, password)
        }
    }

    render() {
        const { isSubmit, value, isShowPassword, adminSignInError } = this.state
        return (
            <React.Fragment>
                <div className="flex-container">
                    <div className="flex-child">
                        <div className='middle'>
                            <img loading='lazy'
                                src='/Icons/artshare-logo.png'
                                width="600"
                                height="450"
                            />
                        </div>
                    </div>

                    <div className="flex-child ">
                        <div className='middle form-card'>
                            <h1 className='welcome-text mt-3'><FormattedMessage id='Login.label.welcome' /></h1>
                            {this.props.isError && (
                                <div className='mx-5 mb-3'>
                                    <p style={{ textAlign: 'center', padding: '5px', background: 'red', color: '#FFF', fontSize: '14px' }}>{this.props.errorMsg === adminSignInError ?
                                        adminSignInError : `Username or Password is incorrect`}</p>
                                </div>
                            )}

                            <div className='form-center mb-5'>
                                <form onSubmit={this.onLoginPressed}>
                                    <div className='input-container mx-5'>
                                        <input
                                            className={isSubmit && !value?.username ? 'input-text error-border' : 'input-text'}
                                            type="text"
                                            onChange={e => this.onChangeValue('username', e)}
                                            placeholder='Username'
                                        />
                                        {isSubmit && !value?.username && <p className='error-text'><FormattedMessage id='Login.error.username' /></p>}
                                    </div>
                                    <div className='input-container mx-5'>
                                        <div className='password-field'>
                                            <input
                                                className={isSubmit && !value?.password ? 'input-text error-border' : 'input-text'}
                                                type={!isShowPassword ? 'password' : 'text'}
                                                onChange={e => this.onChangeValue('password', e)}
                                                placeholder='Password'
                                            />
                                            <span onClick={() => this.setState({ isShowPassword: !isShowPassword })}><i className={`fa ${!isShowPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i></span>
                                        </div>
                                        {isSubmit && !value?.password && <p className='error-text'><FormattedMessage id='Login.error.password' /></p>}
                                    </div>
                                    <div style={{ marginTop: 60 }} className='middle'>
                                        <button className='login-btn' type="submit" disabled={this.props.isLoading}>{this.props.isLoading && <i className="fa fa-spinner fa-spin"></i>} <FormattedMessage id='Login.button.login' /></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        errorMsg: state.api?.user?.authUser?.error,
        isLoading: state.api?.user.authUser.loading,
        isError: state.api?.user.authUser.statusText === 'error',
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loginUser: (username: string, password: string) =>
        loginUser(dispatch, { username, password }),
    onClearUser: () => dispatch({ type: CLEAR_USER }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(LoginPage as any)))
