import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Analytics } from '.'


export const BEGIN_GET_ANALYTICS_BY_ID = 'API/ITEM/BEGIN_GET_ANALYTICS_BY_ID'
export const SUCCESS_GET_ANALYTICS_BY_ID = 'API/ITEM/SUCCESS_GET_ANALYTICS_BY_ID'
export const ERROR_GET_ANALYTICS_BY_ID = 'API/ITEM/ERROR_GET_ANALYTICS_BY_ID'

export const getAnalyticsByIdInitialState = {
    response: undefined,
    loading: false,
}

export interface GetAnalyitcsByIdState {
    response: Analytics | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
    imageUrl?: any
}

export interface GetAnalyticsByIdActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetAnalyitcsByIdState = getAnalyticsByIdInitialState, action: GetAnalyticsByIdActions) => {
    switch (action.type) {
        case BEGIN_GET_ANALYTICS_BY_ID:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ANALYTICS_BY_ID:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_ANALYTICS_BY_ID:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetAnayliticsById = () => ({
    type: BEGIN_GET_ANALYTICS_BY_ID,
})

export const successGetAnalyticsById = (response: AxiosResponse) => ({
    type: SUCCESS_GET_ANALYTICS_BY_ID,
    data: response.data.message,
    status: response.status
})

export const errorGetAnalyticsById = (error: AxiosError) => ({
    type: ERROR_GET_ANALYTICS_BY_ID,
    status: error.response?.status,
})

export const getAnalytics = (dispatch: Dispatch, analyticsId: string) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/analytics/${analyticsId}`
    }
    dispatch(beginGetAnayliticsById())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetAnalyticsById(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetAnalyticsById(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer